.titDadosTec {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 38px;
  text-transform: uppercase;
}
.boxCaractBottom {
  padding-top: 1.5rem;
}
.fichaObs {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}
.fichaExtras {
  display: flex;
  align-items: center;
  line-height: 46px;
}
.fichaExtras svg {
  margin-right: 10px;
}
.fichaExtras1 {
  border-bottom: 1px solid #eaeaea;
}

.fichaExtras2 {
  border-radius: 23px;
  background-color: #f9f9f9;
  padding: 0px 15px;
  margin-bottom: 10px;
}

.boxBottom {
  padding: 0px 40px 0px;
}

.boxCaractBottom .fichaDados {
  width: 14.28%;
}

.carForm .fichaDados {
  width: 33.33333%;
}

.fichaDados {
  display: flex;
  align-items: center;
  width: 25%;
  padding: 1.3vw 0px;
}
.fichaDados > li:first-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 5px;
  width: 40px;
}
.fichaDados > li:last-child > ul > li:first-child {
  color: #a8a8a8;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
}
.fichaDados > li:last-child > ul > li:last-child {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.fichaDados2 {
  justify-content: space-between !important ;
  flex-direction: column !important;
  width: 12.5% !important;
}
.fichaDados2 > li:first-child {
  width: auto !important;
  margin-right: 0px !important;
  margin-bottom: 5px;
}
.fichaDados2 > li:last-child > ul > li:first-child {
  text-align: center !important;
}
.fichaDados2 > li:last-child > ul > li:last-child {
  text-align: center !important;
  justify-content: center !important;
}
.boxBottom2 {
  padding: 30px 0px;
}

.Barra .fichaDados2 {
  flex-direction: row !important;
}
.Barra .fichaDados2 > li > svg {
  margin-right: 10px;
}

@media (max-width: 1400px) {
  .boxCaractBottom .fichaDados {
    width: 20%;
  }
  .boxBottom {
    padding: 0px 20px 0px;
  }
  .fichaDados > li:last-child > ul > li:first-child {
    font-size: 13px;
    line-height: 15px;
  }
  .fichaDados > li:last-child > ul > li:last-child {
    font-size: 13px;
    line-height: 15px;
  }
}

@media (max-width: 1200px) {
  .fichaDados2 {
    width: 14.28% !important;
  }
  .boxBottom2 {
    justify-content: center;
  }
  .boxCaractBottom .fichaDados {
    width: 25%;
  }
}

@media (max-width: 991px) {
  .fichaDados > li:last-child > ul > li:first-child {
    font-size: 15px;
    line-height: 19px;
  }
  .fichaDados > li:last-child > ul > li:last-child {
    font-size: 15px;
    line-height: 19px;
  }
  .fichaDados2 {
    width: 20% !important;
  }
  .boxCaractBottom .fichaDados {
    width: 33.33333%;
  }
  .fichaDados {
    padding: 15px 0px;
  }
}

@media (max-width: 767px) {
  .boxBottom {
    padding: 0px 20px 0px;
    justify-content: center;
  }
  .fichaDados {
    width: 33.3333%;
  }
  .fichaDados2 {
    width: 25% !important;
  }
  .boxCaractBottom .fichaDados {
    width: 33.3333%;
  }
}

@media (max-width: 574px) {
  .boxCaractBottom {
    padding-top: 0;
  }
  .boxBottom {
    padding: 0px 15px 0px;
  }
  .fichaDados {
    width: 50%;
  }

  .boxCaractBottom .fichaDados {
    width: 50%;
  }
}

@media (max-width: 420px) {
  .fichaDados2 {
    width: 33.3333% !important;
  }
}
